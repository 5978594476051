import { useState } from 'react'
import { Link } from 'react-router-dom'

import ModalSign from '../modal/modal'

import cut from '../../assets/cutWhite.png'
import wing from '../../assets/cutWingGold.png'

import './header.sass'

const Header = () => {
    const [openMenuIndex, setOpenMenuIndex] = useState(null)
    const handleMouseEnter = (index) => {
        const rod = document.querySelectorAll('.header__nav__container__menu__element__rod')
        setOpenMenuIndex(index)
        rod[index].classList.add('active')
    }
    const handleMouseLeave = (index) => {
        const rod = document.querySelectorAll('.header__nav__container__menu__element__rod')
        rod[index].classList.remove('active')
        setOpenMenuIndex(null)
    }
    const isMenuOpen = (index) => openMenuIndex === index

    const [ openModal, setOpenModal ] = useState(false)

    return (
        <header className='header'>
            <img className='header__wing' src={wing} alt='Logo Cut aile'/>
            <img className='header__cut' src={cut} alt='Logo Cut'/>
            <nav className='header__nav'>
                <div className='header__nav__container'>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={() => handleMouseLeave(0)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Présentation <i className="fa-solid fa-caret-down"></i></p>
                            <div className='header__nav__container__menu__element__rod'></div>
                            <ul className={isMenuOpen(0) ? 'header__nav__container__menu__element__list open' : 'header__nav__container__menu__element__list close'}>
                                <div className='header__nav__container__menu__element__list__rod'></div>
                                <li><a href='#' rel='noopener noreferrer'>Personnages</a></li>
                                <li><a href='#' rel='noopener noreferrer'>Arsenal</a></li>
                                <li><a href='#' rel='noopener noreferrer'>Cartes</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={() => handleMouseLeave(1)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Médias</p>
                            <div className='header__nav__container__menu__element__rod'></div>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={() => handleMouseLeave(2)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Actualités</p>
                            <div className='header__nav__container__menu__element__rod'></div>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={() => handleMouseLeave(3)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Classement</p>
                            <div className='header__nav__container__menu__element__rod'></div>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={() => handleMouseLeave(4)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Support <i className="fa-solid fa-caret-down"></i></p>
                            <div className='header__nav__container__menu__element__rod'></div>
                            <ul className={isMenuOpen(4) ? 'header__nav__container__menu__element__list open' : 'header__nav__container__menu__element__list close'}>
                                <div className='header__nav__container__menu__element__list__rod'></div>
                                <li><a href='#' rel='noopener noreferrer'>Configurations</a></li>
                                <li><a href='#' target='_blank' rel='noopener noreferrer'>Support <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                                <li><a href='#' target='_blank' rel='noopener noreferrer'>...</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(5)} onMouseLeave={() => handleMouseLeave(5)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>Social <i className="fa-solid fa-caret-down"></i></p>
                            <div className='header__nav__container__menu__element__rod'></div>
                            <ul className={isMenuOpen(5) ? 'header__nav__container__menu__element__list open' : 'header__nav__container__menu__element__list close'}>
                                <div className='header__nav__container__menu__element__list__rod'></div>
                                <li><a href='https://www.facebook.com/profile.php?id=61559585563353' target='_blank' rel='noopener noreferrer'>Facebook <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                                <li><a href='https://x.com/RisexCut' target='_blank' rel='noopener noreferrer'>Twitter <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                                <li><a href='https://discord.gg/K23ksTnQC7' target='_blank' rel='noopener noreferrer'>Discord <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                                <li><a href='#' target='_blank' rel='noopener noreferrer'>... <i className="fa-solid fa-arrow-up-right-from-square"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='header__nav__container__menu' onMouseEnter={() => handleMouseEnter(6)} onMouseLeave={() => handleMouseLeave(6)}>
                        <div className='header__nav__container__menu__element'>
                            <p className='header__nav__container__menu__element__para'>E-sport <i className="fa-solid fa-arrow-up-right-from-square"></i></p>
                            <div className='header__nav__container__menu__element__rod'></div>
                        </div>
                    </div>
                </div>
                <div className='header__nav__container'>
                    <div className='header__nav__container__play'>
                        <i className='fa-solid fa-globe fa-xl'></i>
                        <a className='header__nav__container__play__connexion' onClick={() => {setOpenModal(true)}}>Connexion</a>
                        {openModal && <ModalSign closeModal={setOpenModal} />}
                        <a className='header__nav__container__play__download'>Jouer</a>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default Header