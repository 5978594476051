import './home.sass'

import { ActusHome } from '../../data/actusHome'

import map from '../../assets/map.jpg'
import CutWhite from '../../assets/cutWhite.png'
import BtnCutOrange from '../../assets/Button_Play_Orange.png'
import BtnCutBlack from '../../assets/Button_Play_Black.png'

const Home = () => {
    return (
        <div className='home'>
            <section className='home__acc'>
                <img className='home__acc__img' src={map} alt="" />
                <div className='home__acc__overlay'>
                    <p className='home__acc__overlay__para'>Stratégie, précision et adrénaline en 3v3</p>
                    <img className='home__acc__overlay__img' src={CutWhite} alt="" />
                    <div className='test'>
                        <button className='home__acc__overlay__link'>Jouer maintenant</button>
                    </div>
                    {/* <a className='home__acc__overlay__link' href='#'>
                        <img className='home__acc__overlay__link__img' src={BtnCutOrange} alt="" />
                        <p className='home__acc__overlay__link__para'>Jouer Maintenant</p>
                    </a> */}
                    {/* <button className='home__acc__overlay__btn'>Jouer gratuitement</button> */}
                </div>
            </section>
            <section className='home__actus'>
                <div className='home__actus__container'>
                    <div className='home__actus__container__head'>
                        <h2 className='home__actus__container__head__title'>Dernières Actus</h2>
                        <a className='home__actus__container__head__link' href='#'>Toutes les actus <i className='fa-solid fa-turn-up'></i></a>
                    </div>
                    <div className='home__actus__container__content'>
                        {ActusHome.map((actu) => (
                            <a className='home__actus__container__content__link' key={actu.id} href={actu.link}>
                                <img className='home__actus__container__content__link__img' src={actu.img} alt="" />
                                <p className='home__actus__container__content__link__date'><span>Actualité</span> | {actu.date}</p>
                                <h3 className='home__actus__container__content__link__title'>{actu.title}</h3>
                                <p className='home__actus__container__content__link__para'>{actu.content}</p>
                            </a>
                        ))}

                    </div>
                </div>
            </section>
            <section className='home__game'>
                <div className='home__game__left'>
                    <div className='home__game__left__info'>
                        <h4 className='home__game__left__info__title'>1er Étape</h4>
                        <p className='home__game__left__info__para'>Commencer à jouer avec Rise CUT</p>
                    </div>
                    <div className='test'>
                        <button className='home__game__left__link'>Nous rejoindre</button>
                    </div>
                    {/* <a className='home__game__left__link' href='#'>
                        <img className='home__game__left__link__img' src={BtnCutBlack} alt="" />
                        <p className='home__game__left__link__para'>Nous rejoindre</p>
                    </a> */}
                </div>
                <div className='home__game__right'>
                    <img className='home__game__right__img' src='' alt="" />
                </div>
            </section>
        </div>
    )
}
export default Home