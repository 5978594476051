import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Header from './components/header/header'
import Home from './pages/home/home'
import Account from './pages/account/account'
import Footer from './components/footer/footer'

import './index.sass'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <Header/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/account' element={<Account/>}/>
    </Routes>
    <Footer/>
  </Router>
)