import { useEffect, useState } from 'react'
// import Modal from 'react-modal'

import './modal.sass'

const ModalSign = ({ closeModal }) => {
    async function handleSubmit(e) {
        e.preventDefault()
        console.log(e);
        const nom = e.target[0].value
        const prenom = e.target[1].value
        const age = e.target[2].value
        const pseudo = e.target[3].value
        const mail = e.target[4].value
        const password = e.target[5].value
        const signUp = {
            nom: nom,
            prenom: prenom,
            age: age,
            pseudo: pseudo,
            mail: mail,
            password: password
        }
        console.log(signUp);
        try {
            const res = await fetch('http://localhost:4000/auth/signUp', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(signUp)
            });
        
            // Vérifie le statut de la réponse
            if (res.status === 200) {
                console.log('OK ' + res.status)
            } else if (res.status === 400 || res.status === 401 || res.status === 415) {
                // Si le statut est l'un de ceux attendus (400, 401 ou 415), traite le JSON pour obtenir le message
                const data = await res.json()
                if (data.message) {
                    console.error(data.message)
                } else {
                    console.error('Réponse inattendue du backend :', data)
                }
            } else {
                console.error('Erreur inattendue: ' + res.status)
            }
        } catch (err) {
            console.error("Error:", err)
        }
    }
    async function handleConnex(e) {
        e.preventDefault()
        let pseudos = e.target[0].value
        let passwords = e.target[1].value
        console.log(pseudos);
        console.log(passwords);
        const user = {
            pseudo: pseudos,
            password: passwords
        }
        console.log(e);
        console.log(user)
        try{
            const res = await fetch('http://localhost:4000/auth/signIn', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user)
            })
            if(res.status === 200){
                console.log('OK')
            } else {
                console.error('nop : ' + res.status)
            }
        } catch(err){
            console.error("Error:", err)
        }
    }
    useEffect(()=> {
        const signUpButton = document.getElementById('signUp')
        const signInButton = document.getElementById('signIn')
        const container = document.getElementById('container')
        signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'))
        signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'))

        const stopPropagation = (e) => {
            e.stopPropagation()
        }
        container.addEventListener('click', stopPropagation)
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' || e.key === 'Esc') {
                closeModal(false)
            }
        })
    },[])
    
    return (
        <div className='sign'>
            <div className='sign__background' onClick={() => closeModal(false)}>
                <div className='sign__background__container' id='container'>
                    <div className='sign__background__container__signup'>
                        <form onSubmit={handleSubmit}>
                            <h1>Inscription</h1>
                            <div className='sign__background__container__signup__social'>
                                <a className='sign__background__container__signup__social__element' href='#'><i className="fa-brands fa-facebook fa-xl"></i></a>
                                <a className='sign__background__container__signup__social__element' href='#'><i className="fa-brands fa-google fa-xl"></i></a>
                                <a className='sign__background__container__signup__social__element' href='#'><i className="fa-brands fa-steam fa-xl"></i></a>
                            </div>
                            <span>Ou utilise ton adresse mail pour t'inscrire</span>
                            <div className='sign__background__container__signup__test'>
                                <input id='nom' name='nom' placeholder='Nom' type='text' required/>
                                <input id='prenom' name='prenom' placeholder='Prenom' type='text' required/>
                            </div>
                            <input id='age' name='age' placeholder='Age' type='text' required/>
                            <input id='pseudo' name='pseudo' placeholder='Pseudo' type='text' required/>
                            <input id='mail' name='mail' placeholder='Email' type='text' required/>
                            <input id='password' name='password' placeholder='Mot de passe' type='password' required/>
                            <button className='sign__background__container__signup__btn' type='submit' value='send'>S'inscrire</button>
                        </form>
                    </div>
                    <div className='sign__background__container__signin'>
                        <form onSubmit={handleConnex}>
                            <h1>Connexion</h1>
                            <div className='sign__background__container__signin__social'>
                                <a className='sign__background__container__signin__social__element' href='#'><i className="fa-brands fa-facebook fa-xl"></i></a>
                                <a className='sign__background__container__signin__social__element' href='#'><i className="fa-brands fa-google fa-xl"></i></a>
                                <a className='sign__background__container__signin__social__element' href='#'><i className="fa-brands fa-steam fa-xl"></i></a>
                            </div>
                            <span>Ou connecte toi avec ton adresse mail</span>
                            <input id='pseudos' name='pseudo' placeholder='Pseudo' type='text' />
                            <input id='passwords' name='password' placeholder='Mot de passe' type='password' />
                            <a className='sign__background__container__signin__forgot' href='#'>Mot de passe oublié ?</a>
                            <button className='sign__background__container__signin__btn' type='submit' value='send'>Envoyer</button>
                        </form>
                    </div>
                    <div className='sign__background__container__overlay'>
                        <div className='sign__background__container__overlay__container'>
                            <div className='sign__background__container__overlay__container__left'>
                                <h1>Connexion</h1>
                                <p>Connecte toi ici si tu as déjà un compte</p>
                                <button className='sign__background__container__overlay__container__left__btn' id='signIn'>Se connecter</button>
                            </div>
                            <div className='sign__background__container__overlay__container__right'>
                                <h1>Créer un compte !</h1>
                                <p>Inscris toi si tu n'as toujours pas de compte</p>
                                <button className='sign__background__container__overlay__container__right__btn' id='signUp'>S'inscrire</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalSign