export const ActusHome = [
    {
        "id": "aaaa",
        "img": "",
        "title": "Nouvelle version du jeu",
        "content": "Dernière mise à jour du jeu avec des nouvelles fonctionnalités et améliorations.",
        "date": "11/11/2024",
        "link": "#"
    },
    {
        "id": "aaab",
        "img": "",
        "title": "Nouveau mode de jeu",
        "content": "Nouveau mode de jeu créé pour améliorer les performances et le jeu.",
        "date": "11/11/2024",
        "link": "#"
    },
    {
        "id": "aaac",
        "img": "",
        "title": "Nouvelles armes",
        "content": "Nouvelles armes et techniques de combat ajoutées au jeu pour améliorer les performances et le jeu.",
        "date": "11/11/2024",
        "link": "#"
    }
]