import Cut from '../../assets/cutWhite.png'
import Avatar from '../../assets/Avatar.png'
import Pegi from '../../assets/Pegi/16.jpg'
import PegiPurchase from '../../assets/Pegi/Purchase.png'
import PegiViolence from '../../assets/Pegi/Violence.jpg'
import PegiLanguage from '../../assets/Pegi/Language.jpg'

import './footer.sass'

const Footer = () => {
    return (
        <footer className='footer'>
            <section className='footer__sect'>
                <img className='footer__sect__logo' src={Cut} alt='' />
            </section>
            <section className='footer__sect'>
                <div className='footer__sect__social'>
                    <a href='https://www.facebook.com/profile.php?id=61559585563353' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-facebook"></i></a>
                    <a href='https://x.com/RisexCut' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-x-twitter"></i></a>
                    <a href='#' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-instagram"></i></a>
                    <a href='https://www.tiktok.com/@risexcut?lang=fr' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-tiktok"></i></a>
                    <a href='https://www.youtube.com/@Risexcut' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-youtube"></i></a>
                    <a href='https://discord.gg/K23ksTnQC7' target='_blank' rel='noopener noreferrer' className='footer__sect__social__link'><i className="fa-brands fa-discord"></i></a>
                </div>
                <div className='footer__sect__infos'>
                    <a href='#' target='_blank' rel='noopener noreferrer' className='footer__sect__infos__link'>Mentions légales</a>
                    <p className='footer__sect__infos__rod'>-</p>
                    <a href='#' target='_blank' rel='noopener noreferrer' className='footer__sect__infos__link'>Politique de confidentialité</a>
                    <p className='footer__sect__infos__rod'>-</p>
                    <a href='#' target='_blank' rel='noopener noreferrer' className='footer__sect__infos__link'>Politique sur les cookies</a>
                </div>
                <div className='footer__sect__copyright'>
                    <p className='footer__sect__copyright__para'>Copyright © 2024 Rise CUT - Tous droits réservés.</p>
                </div>
                <div className='footer__sect__pegi'>
                    <a className='footer__sect__pegi__link' href='https://pegi.info/fr'>
                        <img className='footer__sect__pegi__link__age' src={Pegi} alt='Pegi Age' />
                        <img className='footer__sect__pegi__link__content' src={PegiLanguage} alt='Pegi Langage' />
                        <img className='footer__sect__pegi__link__content' src={PegiViolence} alt='Pegi Violence' />
                        <img className='footer__sect__pegi__link__content' src={PegiPurchase} alt='Pegi Achat' />
                    </a>
                </div>
            </section>
            <section className='footer__sect'>
                <img className='footer__sect__avatar' src={Avatar} alt='' />
            </section>
        </footer>
    )
}
export default Footer