import './account.sass'

const Account = () => {
    async function handleSubmit(e) {
        e.preventDefault()
        const nom = document.getElementById('nom').value
        const prenom = document.getElementById('prenom').value
        const age = document.getElementById('age').value
        const pseudo = document.getElementById('pseudo').value
        const mail = document.getElementById('mail').value
        const password = document.getElementById('password').value
        const signUp = {
            nom: nom,
            prenom: prenom,
            age: age,
            pseudo: pseudo,
            mail: mail,
            password: password
        }
        try {
            const res = await fetch('http://localhost:4001/auth/signUp', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(signUp)
            });
        
            // Vérifie le statut de la réponse
            if (res.status === 200) {
                console.log('OK ' + res.status)
            } else if (res.status === 400 || res.status === 401 || res.status === 415) {
                // Si le statut est l'un de ceux attendus (400, 401 ou 415), traite le JSON pour obtenir le message
                const data = await res.json()
                if (data.message) {
                    console.error(data.message)
                } else {
                    console.error('Réponse inattendue du backend :', data)
                }
            } else {
                console.error('Erreur inattendue: ' + res.status)
            }
        } catch (err) {
            console.error("Error:", err)
        }
    }
    async function handleConnex(e) {
        e.preventDefault()
        let pseudos = document.getElementById('pseudo').value
        let passwords = document.getElementById('password').value
        const user = {
            pseudo: pseudos,
            password: passwords
        }
        console.log(user)
        try{
            const res = await fetch('http://localhost:4001/auth/signIn', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user)
            })
            if(res.status === 200){
                console.log('OK')
            } else {
                console.error('nop : ' + res.status)
            }
        } catch(err){
            console.error("Error:", err)
        }
    }
    return (
        <div className='account'>
            <form onSubmit={handleSubmit}>
                <input id='nom' name='nom' placeholder='Nom' type='text' required/>
                <input id='prenom' name='prenom' placeholder='Prenom' type='text' required/>
                <input id='age' name='age' placeholder='Age' type='text' required/>
                <input id='pseudo' name='pseudo' placeholder='Pseudo' type='text' required/>
                <input id='mail' name='mail' placeholder='Email' type='text' required/>
                <input id='password' name='password' placeholder='Mot de passe' type='password' required/>
                <button type='submit' value='send'>Envoyer</button>
            </form>
            <form onSubmit={handleConnex}>
                <input id='pseudos' name='pseudo' placeholder='Pseudo' type='text' />
                <input id='passwords' name='password' placeholder='Mot de passe' type='text' />
                <button type='submit' value='send'>Envoyer</button>
            </form>
        </div>
    )
}
export default Account